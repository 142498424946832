import React, { Component } from "react";
import "./Export.css";
import { FormGroup, Input, Row, Col } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
class Export extends Component {
  constructor(props) {
    super(props);
    this.state = {
      export: "",
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
  }
  exportToCSV = (csvData, fileName) => {
    console.log("csvDAta", csvData);
    let formatCSVdata = csvData.map((data) => {
      if (data.hasOwnProperty("memberId")) {
        delete data.memberId;
      }
      return data;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    let ws = XLSX.utils.json_to_sheet(formatCSVdata);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  handleOnChange(event) {
    const { name, value } = event.target;
    console.log("name", event.target.name);
    console.log("value", event.target.value);
    if (value === "excel") {
      this.exportToCSV(this.props.csvData, this.props.fileName);
    }
    this.setState({
      [name]: "",
    });
  }
  componentDidMount() {}
  render() {
    return (
      <Row className="offset-md-10 export-container">
        <h5>Export to:</h5>
        <Col className="pr-1 export-btn" md="7">
          <FormGroup>
            <Input
              type="select"
              name="export"
              value={this.state.export}
              onChange={this.handleOnChange}>
              <option value="">Select</option>
              {/* <option value="pdf">Pdf</option> */}
              <option value="excel">Excel</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default Export;
