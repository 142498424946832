import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";

import utils from "./../../utils";
import EndpointConstant from "./../../constants/EndpointConstant";
import MessageConstant from "./../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";
import ModelPopup from "../ModelPopup/ModelPopup";
import moment from "moment";
import Export from "../Export/Export";
import TotalAmount from "../Common/TotalAmount/TotalAmount";

class ZakathList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zakaths: [],
      showModel: false,
      dataOnModelConfirm: {},
      showDeleteModel: false,
      dataConfirmOnDelete: {},
      TotalAmt: "",
      contributionZakath: {},
    };
    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCloseOnDelete = this.handleCloseOnDelete.bind(this);
    this.child = React.createRef();
    console.log("props", props);
  }

  handleEdit(e, rowData) {
    console.log("handleEdit", rowData);
    this.props.onEditZakath(rowData);
  }

  onDeleteClick(e, rowData) {
    console.log("ondelete rowData", rowData);
    let data = {
      id: rowData._id,
    };
    this.setState({
      showDeleteModel: true,
      dataConfirmOnDelete: data,
    });
  }
  handleDelete() {
    this.handleCloseOnDelete();
    utils.showLoader();
    utils
      .makeRequest(
        this,
        EndpointConstant.DELETE_ZAKATH,
        "post",
        this.state.dataConfirmOnDelete
      )
      .then((response) => {
        utils.hideLoader();
        let data = response.data;
        console.log("response add", response);
        this.child.current.notify("tr", 2, data.message);
        this.getContributionList();
      })
      .catch((error) => {
        utils.hideLoader();
        let err = error.response;
        console.log("errors", err);
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
        //this.child.current.notify("tr", 3, this.handleError(err));
      });
  }

  handleCloseOnDelete() {
    this.setState({
      showDeleteModel: false,
    });
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          style={{ marginRight: ".5em" }}
          onClick={(e) => this.handleEdit(e, rowData)}></Button>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={(e) => this.onDeleteClick(e, rowData)}></Button>
      </div>
    );
  }

  componentDidMount() {
    this.getContributionList();
  }
  componentWillReceiveProps(nextProps) {
    console.log("component will receive props", nextProps);
    let collections = nextProps.onFilteredData.zakath;
    let filterReset = nextProps.onClearReset;
    // if (this.nexttProps.onFilteredData) {
    this.setState({
      zakaths: this.changeDate(collections),
      totalAmt: nextProps.onFilteredData.totalAmount,
    });
    if (filterReset) {
      this.getContributionList();
    }
    //}
  }

  getContributionList() {
    utils
      .makeRequest(this, EndpointConstant.ZAKATH_LIST, "get")
      .then((response) => {
        utils.hideLoader();
        this.setState(
          {
            zakaths: this.changeDate(response.data.zakath),
            totalAmt: response.data.totalAmount,
          },
          () => {
            this.getExpenditureList();
          }
        );
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("errors", error);
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }
  changeDate(data) {
    let changedData = [];
    if (data) {
      changedData = data.map((items) => {
        items.date = moment.unix(parseInt(items.date)).format("DD/MM/YYYY");
        return items;
      });
    }
    return changedData;
  }
  getExpenditureList() {
    utils
      .makeRequest(this, EndpointConstant.EXPENDITURE_LIST, "get")
      .then((response) => {
        utils.hideLoader();
        this.setState({
          contributionZakath: response.data.contributionZakath,
        });
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("errors", error);
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }
  render() {
    //let paginatorLeft = <Button icon="pi pi-refresh" />;
    //let paginatorRight = <Button icon="pi pi-cloud-upload" />;
    const columns = [
      { field: "memberCode", header: "Code" },
      { field: "name", header: "Name" },
      { field: "date", header: "Date" },
      { field: "amount", header: "Amount" },
      { field: "paymentMode", header: "Payment Mode" },
      { field: "action", header: "Action" },
    ];

    const dynamicColumns = columns.map((col, i) => {
      let template =
        col.field === "action" ? (
          <Column
            key={col.field}
            body={this.actionTemplate}
            style={{
              textAlign: "center",
              width: "8em",
              display:
                utils.getSessionItem("role") === "admin"
                  ? "table-cell"
                  : "none",
            }}
          />
        ) : (
          <Column key={col.field} field={col.field} header={col.header} />
        );
      return template;
    });
    return (
      <div>
        <ToasterMessage ref={this.child} />

        <ModelPopup
          isOpen={this.state.showDeleteModel}
          handleOnClose={this.handleCloseOnDelete}
          Message={MessageConstant.CONFIRM_DELETE_ZAKATH}
          handleOnConfirmation={this.handleDelete}
        />
        <Export csvData={customizeData(this.state.zakaths)} fileName="Zakath" />
        <DataTable
          value={this.state.zakaths}
          paginator={true}
          responsive={true}
          header="Zakath"
          /*  paginatorLeft={paginatorLeft}
                    paginatorRight={paginatorRight} */
          rows={5}>
          {dynamicColumns}
        </DataTable>
        <TotalAmount
          totalAmt={this.state.totalAmt}
          totalRecord={this.state.contributionZakath}
        />
      </div>
    );
  }
}
function customizeData(zakath) {
  let clonedObj = zakath.map((clonedData) => ({ ...clonedData }));
  let zakathArr = clonedObj.map((data) => {
    data["Member Code"] = data["memberCode"];
    data["Member Name"] = data["memberName"];
    data["Member Type"] = data["memberType"];
    data["Guest Name"] = data["guestName"];
    data["Name"] = data["name"];
    data["Date"] = data["date"];
    data["Amount"] = data["amount"];
    data["Payment Mode"] = data["paymentMode"];
    delete data["__v"];
    delete data["_id"];
    delete data["createdAt"];
    delete data["updatedAt"];
    delete data["memberCode"];
    delete data["memberName"];
    delete data["memberType"];
    delete data["guestName"];
    delete data["name"];
    delete data["date"];
    delete data["amount"];
    delete data["paymentMode"];
    return data;
  });
  return zakathArr;
}

export default ZakathList;
