import axios from "axios";
/*
*axios({
  method: 'post',
  url: '/user/12345',
  data: {
    firstName: 'Fred',
    lastName: 'Flintstone'
  }
});
*/
function makeRequest(self, endpointName, method, data, headerData) {
  showLoader();
  let headers = {
    "Content-Type": "application/json",
    userid: getSessionItem("userId"),
    role: getSessionItem("role"),
    "x-access-token": getSessionItem("token"),
  };
  if (headerData) {
    Object.assign(headers, headerData);
  }
  return axios({
    method: method,
    headers: headers,
    url: endpointName,
    data: data,
  });
}

function gotoPage(path) {
  showLoader();
  setTimeout(() => {
    window.location.hash = path;
    hideLoader();
  }, 1000);
}

function showLoader() {
  //if (document.getElementById("loader"))
  document.getElementById("loader").style.display = "block";
}

function hideLoader() {
  document.getElementById("loader").style.display = "none";
}

function setSessionItem(key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

function getSessionItem(key) {
  return JSON.parse(window.sessionStorage.getItem(key));
}

function removeSessionItem(key) {
  window.sessionStorage.removeItem(key);
}

function hasSessionItem(key) {
  if (getSessionItem(key) === null) {
    return false;
  } else {
    return true;
  }
}

function validateForm(errors) {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
}

function logoutUnauthorized(err) {
  if (err.status == 401) {
    removeSessionItem("userId");
    removeSessionItem("userName");
    removeSessionItem("role");
    removeSessionItem("token");
    gotoPage("/login");
  }
}

export default {
  makeRequest,
  setSessionItem,
  getSessionItem,
  hasSessionItem,
  removeSessionItem,
  showLoader,
  hideLoader,
  gotoPage,
  validateForm,
  logoutUnauthorized,
};
