/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Contribution.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";
import { object } from "prop-types";

class ContributionForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("add new props in form", props);
    const {
      memberName,
      memberId,
      memberCode,
      guestName,
      date,
      amount,
      paymentMode,
      _id,
    } = this.props.onEditContribution;
    let splitDate = date ? date.split("/") : "";
    let editDate = splitDate
      ? splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]
      : "";
    console.log("editDte", editDate);
    this.state = {
      member: memberName ? memberName : "",
      guest: guestName ? guestName : "",
      date: date ? new Date(editDate) : "",
      amount: amount ? amount : "",
      paymentType: paymentMode ? paymentMode : "",
      errors: { member: "", guest: "", date: "", amount: "", paymentType: "" },
      formValid: false,
      memberList: [],
      memberData: [],
      selectedMember: "",
      editId: _id ? _id : "",
      memberCode: memberCode ? memberCode : "",
      memberId: memberId ? memberId : "",
      filterCheck: false,
      filterData: "",
    };
    this.child = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this);
    this.addContribution = this.addContribution.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleOnCheck = this.handleOnCheck.bind(this);
  }

  setErrorMessage(state) {
    const { member, guest, date, amount, paymentType, errors } = state;
    if (member === "" && this.props.memberType === "member") {
      errors.member = MessageConstant.MEMBER_REQUIRED;
      errors.guest = "";
    }
    if (guest === "" && this.props.memberType === "guest") {
      errors.guest = MessageConstant.GUEST_REQUIRED;
      errors.member = "";
    }
    if (date === "") {
      errors.date = MessageConstant.DATE_REQUIRED;
    }
    if (amount === "") {
      errors.amount = MessageConstant.AMOUNT_REQUIRED;
    }
    if (paymentType === "") {
      errors.paymentType = MessageConstant.PAYMETHOD_REQUIRED;
    }
    return errors;
  }

  componentDidMount() {
    this.getMemberList();
  }

  getMemberList() {
    utils
      .makeRequest(
        this,
        EndpointConstant.MEMBER_LIST,
        "get",
        {},
        { option: "select" }
      )
      .then((response) => {
        console.log("response", response);
        utils.hideLoader();
        this.setState({
          memberList: response.data.user,
          memberData: response.data.user,
        });
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("errors", error);
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }

  handleOnChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "member":
        let dataTag = event.target[event.target.selectedIndex].getAttribute(
          "data-tag"
        );
        errors.member =
          this.props.memberType === "member" && value === ""
            ? MessageConstant.MEMBER_REQUIRED
            : "";
        this.setState({ [name]: value, guest: "", selectedMember: dataTag });
        break;
      case "guest":
        errors.guest =
          this.props.memberType === "guest" && value === ""
            ? MessageConstant.GUEST_REQUIRED
            : "";
        this.setState({ [name]: value, member: "" });
        break;
      case "date":
        console.log("valuesss", value);
        errors.date = value === "" ? MessageConstant.DATE_REQUIRED : "";
        //let date = moment(value).format('MM/DD/YYYY').toString();
        //let date = moment(value).unix();
        console.log("dateeeee", moment(value).unix());
        console.log(
          "conver date",
          moment.unix(moment(value).unix()).format("DD/MM/YYYY HH:mm")
        );
        this.setState({ [name]: value });
        break;
      case "amount":
        errors.amount = value === "" ? MessageConstant.AMOUNT_REQUIRED : "";
        const amount = /^[0-9.\b]+$/;
        if ((value === "" || amount.test(value)) && value.length <= 10) {
          this.setState({ [name]: value });
        }
        break;
      case "paymentType":
        errors.paymentType =
          value === "" ? MessageConstant.PAYMETHOD_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      default:
        break;
    }
  }
  handleOnCancel() {
    this.props.onCancelContribution();
  }
  handleOnClear(event) {
    event.preventDefault();
    this.setState({
      member: "",
      guest: "",
      date: "",
      amount: "",
      paymentType: "",
    });
  }
  handleFilter(e) {
    const { name, value } = e.target;
    if (value != "") {
      let filter = this.state.memberData.filter((data) => {
        return data.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
      this.setState({
        memberList: filter,
        filterData: value,
      });
    } else {
      this.setState({
        memberList: this.state.memberData,
        filterData: value,
      });
    }
  }
  handleOnCheck(e) {
    this.setState(
      {
        filterCheck: !this.state.filterCheck,
      },
      () => {
        if (!this.state.filterCheck) {
          this.setState({
            memberList: this.state.memberData,
            filterData: "",
          });
        }
      }
    );
  }
  addContribution(event) {
    event.preventDefault();
    let errorMessage = this.setErrorMessage(this.state);
    this.setState({ errors: errorMessage });
    this.setState({ formValid: utils.validateForm(this.state.errors) });
    console.log("this.state", this.state);

    setTimeout(() => {
      const { formValid } = this.state;
      if (formValid) {
        console.log("formValid", this.state);
        let contributionData = {};
        if (this.props.memberType === "guest") {
          contributionData.memberCode = "-";
          contributionData.memberName = "-";
          contributionData.memberId = "-";
          contributionData.guestName = this.state.guest;
          contributionData.name = this.state.guest;
        } else {
          let memberInfo = this.state.memberList.filter(
            (info) => info.code === this.state.selectedMember
          );
          console.log("memberInfo", memberInfo);
          contributionData.memberCode = this.state.selectedMember
            ? memberInfo[0].code
            : this.state.memberCode;
          contributionData.memberName = this.state.selectedMember
            ? memberInfo[0].name
            : this.state.member;
          contributionData.memberId = this.state.selectedMember
            ? memberInfo[0]._id
            : this.state.memberId;
          contributionData.guestName = "-";
          contributionData.name = this.state.selectedMember
            ? memberInfo[0].name
            : this.state.member;
        }

        contributionData.memberType = this.props.memberType;
        contributionData.date = moment(this.state.date).unix();
        contributionData.amount = this.state.amount;
        contributionData.paymentMode = this.state.paymentType;
        console.log("contributionDate", contributionData);
        console.log("valid fields");
        if (this.props.isAddOrEditFlag === "new") {
          utils
            .makeRequest(
              this,
              EndpointConstant.ADD_CONTRIBUTION,
              "post",
              contributionData
            )
            .then((response) => {
              utils.hideLoader();
              let data = response.data;
              console.log("response add", response);
              this.child.current.notify("tr", 2, data.message);
              //utils.gotoPage("/admin/members");
              setTimeout(() => {
                this.props.onCancelContribution();
              }, 1000);
            })
            .catch((error) => {
              utils.hideLoader();
              let err = error.response;
              console.log("errors", err);
              this.child.current.notify("tr", 3, this.handleError(err));
              const err401 = error.response;
              utils.logoutUnauthorized(err401);
            });
        } else if (this.props.isAddOrEditFlag === "edit") {
          console.log("type of", this.state.date);
          contributionData.date = moment(this.state.date).unix();
          contributionData.id = this.state.editId;
          utils
            .makeRequest(
              this,
              EndpointConstant.UPDATE_CONTRIBUTION,
              "post",
              contributionData
            )
            .then((response) => {
              utils.hideLoader();
              let data = response.data;
              console.log("response edit", response);
              this.child.current.notify("tr", 2, data.message);
              //utils.gotoPage("/admin/members");
              setTimeout(() => {
                this.props.onCancelContribution();
              }, 1000);
            })
            .catch((error) => {
              utils.hideLoader();
              let err = error.response;
              console.log("errors", err);
              this.child.current.notify("tr", 3, err.data.message);
              const err401 = error.response;
              utils.logoutUnauthorized(err401);
            });
        } else {
          this.props.onCancelContribution();
        }
      } else {
        console.log("invalid form");
      }
    }, 500);
  }
  render() {
    console.log("this.state", this.state);
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate());
    const { errors, memberList } = this.state;
    const memberOption =
      memberList &&
      this.state.memberList.map((data) => {
        return (
          <option key={data.code} data-tag={data.code} value={data.name}>
            {data.name}
          </option>
        );
      });
    return (
      <CardBody>
        <ToasterMessage ref={this.child} />
        <div className="rowCentered">
          <Form onSubmit={this.addContribution}>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <span>
                    <label className="required">Member</label>
                    {this.props.memberType === "member" && (
                      <span
                        className={
                          this.state.filterCheck
                            ? "filterEnable"
                            : "filterOnDisable"
                        }>
                        <label
                          className={
                            this.state.filterCheck
                              ? "filterEnableLabel"
                              : "filterDisableLabel"
                          }>
                          Enable filter
                        </label>
                        <Input
                          type="checkbox"
                          name="filterCheck"
                          defaultChecked={this.state.filterCheck}
                          onChange={this.handleOnCheck}
                        />
                        <Input
                          type="text"
                          name="filterMember"
                          onChange={this.handleFilter}
                          className="filterMember"
                          value={this.state.filterData}
                          style={{
                            display: this.state.filterCheck ? "block" : "none",
                          }}
                          placeholder="Search Member"
                        />
                      </span>
                    )}
                  </span>

                  {this.props.memberType === "member" ? (
                    <React.Fragment>
                      <Input
                        type="select"
                        name="member"
                        id="member"
                        value={this.state.member}
                        onChange={this.handleOnChange}>
                        <option value="">Select Name</option>
                        {memberOption}
                      </Input>
                      <p className="text-danger">{errors.member}</p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Input
                        placeholder="Guest"
                        type="text"
                        name="guest"
                        value={this.state.guest}
                        onChange={this.handleOnChange}
                      />
                      <p className="text-danger">{errors.guest}</p>
                    </React.Fragment>
                  )}
                </FormGroup>
              </Col>

              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">Date</label>
                  <Calendar
                    style={{ width: "99%" }}
                    inputStyle={{ width: "94%", border: "1px solid #ddd" }}
                    dateFormat="dd/mm/yy"
                    showIcon={true}
                    value={this.state.date}
                    placeholder="Date (DD/MM/YYYY)"
                    name="date"
                    maxDate={maxDate}
                    onChange={this.handleOnChange}
                    monthNavigator
                    yearNavigator
                    yearRange="2014:2030"></Calendar>
                  <p className="text-danger">{errors.date}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">Amount</label>
                  <Input
                    placeholder="Amount"
                    type="text"
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleOnChange}
                  />
                  <p className="text-danger">{errors.amount}</p>
                </FormGroup>
              </Col>

              {/* <Row>
              <Col className="pr-1" md="12">
                <FormGroup className="resetBottom">
                  <Label className="form-check-label required">
                    Contribution Type
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup className="memberOption">
                  <Label className="form-check-label">
                    <Input
                      type="radio"
                      name="memberId"
                      id="memberId1"
                      value="donation"
                      checked={this.state.selectedOption === "donation"}
                      onChange={this.handleOnChangeContributionType}
                    />
                    Donation
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
              </Col>
              <Col className="pr-1" md="6">
                <FormGroup className="memberOption">
                  <Label className="form-check-label">
                    <Input
                      type="radio"
                      name="memberId"
                      id="memberId2"
                      value="zakath"
                      checked={this.state.selectedOption === "zakath"}
                      onChange={this.handleOnChangeContributionType}
                    />
                    Zakath
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
              </Col>
            </Row> */}

              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">Payment Method</label>
                  <Input
                    type="select"
                    name="paymentType"
                    value={this.state.paymentType}
                    onChange={this.handleOnChange}>
                    <option value="">Select Method</option>
                    <option value="Gpay">Gpay</option>
                    <option value="Cash">Cash</option>
                    <option value="Net banking">Net banking</option>
                  </Input>
                  <p className="text-danger">{errors.paymentType}</p>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <div className="update ml-auto mr-auto">
                <Button
                  className="btn-round ml-4"
                  color="primary"
                  type="submit">
                  Save
                </Button>
                <Button
                  className="btn-round ml-4"
                  color="secondary"
                  onClick={this.handleOnCancel}>
                  Cancel
                </Button>
                <Button
                  className="btn-round ml-4"
                  color="info"
                  onClick={this.handleOnClear}>
                  Clear
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </CardBody>
    );
  }
}

export default ContributionForm;
