/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Notifications from "views/Notifications.jsx";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/Tables.jsx";
import Maps from "views/Map.jsx";
import UserPage from "views/User.jsx";
import Members from "views/Members.jsx";
import Contributions from "views/Contributions.jsx";
import Zakath from "views/Zakath.jsx";
import Expenditure from "views/Expenditure.jsx";
import User from "views/User.jsx";
import Settings from "views/Settings.jsx";

var routes = [
  {
    showSidebar: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },
  {
    showSidebar: false,
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: Icons,
    layout: "/admin",
  },
  {
    showSidebar: false,
    path: "/maps",
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    component: Maps,
    layout: "/admin",
  },
  {
    showSidebar: false,
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin",
  },
  {
    showSidebar: false,
    path: "/user-page",
    name: "User Profile",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    layout: "/admin",
  },
  {
    showSidebar: false,
    path: "/tables",
    name: "Table List",
    icon: "nc-icon nc-tile-56",
    component: TableList,
    layout: "/admin",
  },
  {
    showSidebar: false,
    path: "/typography",
    name: "Typography",
    icon: "nc-icon nc-caps-small",
    component: Typography,
    layout: "/admin",
  },
  {
    showSidebar: true,
    path: "/contribution",
    name: "Contribution",
    icon: "fas fa-handshake",
    component: Contributions,
    layout: "/admin",
  },
  {
    showSidebar: true,
    path: "/zakath",
    name: "Zakath",
    icon: "fas fa-hands",
    component: Zakath,
    layout: "/admin",
  },
  {
    showSidebar: true,
    path: "/expenditure",
    name: "Expenditure",
    icon: "nc-icon nc-money-coins",
    component: Expenditure,
    layout: "/admin",
  },
  {
    showSidebar: true,
    path: "/members",
    name: "Members",
    icon: "fas fa-users",
    component: Members,
    layout: "/admin",
  },
  /*  {
    showSidebar: false,
    path: "/members/addmember",
    name: "Add Member",
    component: AddMember,
    layout: "/admin"
  }, */
  {
    showSidebar: true,
    path: "/user",
    name: "User Profile",
    //icon: "nc-icon nc-settings-gear-65",
    icon: "fas fa-user",
    component: User,
    layout: "/admin",
  },
  {
    showSidebar: true,
    path: "/settings",
    name: "Settings",
    //icon: "nc-icon nc-settings-gear-65",
    icon: "nc-icon nc-settings-gear-65",
    component: Settings,
    layout: "/admin",
  },
];
export default routes;
