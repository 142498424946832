import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import SpinnerLoader from "./../components/Loader/Loader";
import utils from "./../utils";
import EndpointConstant from "./../constants/EndpointConstant";
import ToasterMessage from "./../components/Notification/ToasterMessage";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import MessageConstant from "constants/MessageConstant";
import logo from "alnLogo.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      password: "",
      errors: { mobile: "", password: "" },
      formValid: false,
      isDisabled: false,
    };
    this.login = this.login.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.child = React.createRef();
    utils.removeSessionItem("userId");
    utils.removeSessionItem("userName");
    utils.removeSessionItem("role");
    utils.removeSessionItem("token");
  }
  setErrorMessage(state) {
    const { mobile, password, errors } = state;
    if (mobile === "") {
      errors.mobile = MessageConstant.MOBILE_REQUIRED;
    }
    if (password === "") {
      errors.password = MessageConstant.PASSWORD_REQUIRED;
    }
    return errors;
  }

  login(event) {
    event.preventDefault();
    let errorMessage = this.setErrorMessage(this.state);
    this.setState({ errors: errorMessage });
    this.setState({
      formValid: utils.validateForm(this.state.errors),
      isDisabled: true,
    });

    console.log("this.state", this.state);
    setTimeout(() => {
      const { formValid } = this.state;
      if (formValid) {
        let data = {
          mobile: this.state.mobile,
          password: this.state.password,
        };
        utils
          .makeRequest(this, EndpointConstant.LOGIN, "post", data)
          .then((response) => {
            utils.hideLoader();
            let data = response.data;
            console.log("response", response);
            if (data.success == true) {
              utils.setSessionItem("userId", data.userDetail.id);
              utils.setSessionItem("userName", data.userDetail.name);
              utils.setSessionItem("role", data.userDetail.type);
              utils.setSessionItem("token", data.userDetail.token);
              //this.props.history.push("/admin/dashboard");
              this.child.current.notify("tr", 2, data.message);
              utils.gotoPage("/admin/dashboard");
            }
          })
          .catch((error) => {
            utils.hideLoader();
            this.setState({
              isDisabled: false,
            });
            let err = error.response;
            console.log("errors", err);
            if (err.data.status == 401) {
              this.child.current.notify("tr", 3, err.data.message);
            }
          });
      } else {
        console.log("Invalid fields");
        this.setState({
          isDisabled: false,
        });
      }
    }, 500);
  }
  handleOnChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "mobile":
        errors.mobile = value === "" ? MessageConstant.MOBILE_REQUIRED : "";
        const regex = /^[0-9.\b]+$/;
        if (regex.test(value)) {
          console.log("dffdf", value);
          this.setState({
            [name]: value,
          });
        } else {
          event.target.value = value.replace(value, "");
        }
        break;
      case "password":
        errors.password = value === "" ? MessageConstant.PASSWORD_REQUIRED : "";
        this.setState({
          [name]: value,
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { mobile, password } = this.state.errors;
    return (
      <div className="full-page">
        <ToasterMessage ref={this.child} />
        <div className="login-page">
          <Col className="ml-auto mr-auto col-md-6 col-lg-4">
            <Card className="card-login">
              <CardHeader>
                <CardTitle tag="h3" className="text-center">
                  <div className="login-logo-img">
                    <img src={logo} alt="react-logo" />
                  </div>
                </CardTitle>
              </CardHeader>
              <Form onSubmit={this.login}>
                <CardBody>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="nc-icon nc-single-02"></i>
                      </span>
                    </div>
                    <input
                      placeholder="Mobile No."
                      type="text"
                      className="form-control"
                      name="mobile"
                      autoComplete="off"
                      onChange={this.handleOnChange}
                    />
                    <div className="col-md-12">
                      <p className="text-danger">{mobile}</p>
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="nc-icon nc-key-25"></i>
                      </span>
                    </div>
                    <input
                      placeholder="Password"
                      autoComplete="off"
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={this.handleOnChange}
                    />
                    <div className="col-md-12">
                      <p className="text-danger">{password}</p>
                    </div>
                  </div>
                  <br />
                  {/*  <div className="form-group">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value=""
                          checked=""
                        />
                        <span className="form-check-sign"></span>Subscribe to
                        newsletter
                      </label>
                    </div>
                  </div> */}
                </CardBody>
                <CardFooter>
                  <button
                    className="btn-round mb-3 btn btn-warning btn-block"
                    onClick={this.login}
                    disabled={
                      (this.state.mobile == "" && this.state.password == "") ||
                      this.state.isDisabled
                    }>
                    Login
                  </button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </div>
        <div id="loader">
          <SpinnerLoader />
        </div>
      </div>
    );
  }
}

export default Login;
