/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Settings.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";
//import { InputSwitch } from "primereact/inputswitch";
import { Accordion, AccordionTab } from "primereact/accordion";
import SendEmailFromEditor from "./SendEmailFromEditor";
import ResetPassword from "./ResetPassword";

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      activeIndex: [0],
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    this.getMemberList();
  }

  getMemberList() {
    utils
      .makeRequest(
        this,
        EndpointConstant.MEMBER_LIST,
        "get",
        {},
        { option: "select" }
      )
      .then((response) => {
        utils.hideLoader();
        this.setState({
          users: response.data.user,
        });
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("err", JSON.stringify(error.response));
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }

  render() {
    const { users } = this.state;
    return (
      <Card className="cardRoot">
        <ToasterMessage ref={this.child} />
        <Row>
          <Col md="12">
            <Accordion
              multiple={true}
              activeIndex={this.state.activeIndex}
              onTabChange={(e) => this.setState({ activeIndex: e.index })}>
              <AccordionTab header="Email Notification">
                <SendEmailFromEditor users={users}></SendEmailFromEditor>
              </AccordionTab>
              <AccordionTab header="Reset Password">
                <ResetPassword users={users}></ResetPassword>
              </AccordionTab>
            </Accordion>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Setting;
