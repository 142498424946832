import React, { Component } from "react";
function TotalAmount(props) {
  console.log("props", props);
  let totalObj = "";
  let availableContribution = "";
  let availableZakath = "";
  if (props.totalRecord != null) {
    totalObj = props.totalRecord;
    availableContribution =
      totalObj.totalContribution - totalObj.contributionExpenditure;
    availableZakath = totalObj.totalZakath - totalObj.zakathExpenditure;
  }

  return (
    <React.Fragment>
      {props.totalRecord != null && (
        <div className="row" style={{ margin: 0 }}>
          <div className="col-md-9">
            <div className="availableData">
              <p>
                <span className="totalAvailAmt">
                  Total Contribution Amount: <span>&#8377;</span>
                  {totalObj.totalContribution ? totalObj.totalContribution : 0}
                  /-
                </span>
                <span className="totalAvailAmt">
                  Total Zakath Amount: <span>&#8377;</span>{" "}
                  {totalObj.totalZakath ? totalObj.totalZakath : 0}
                  /-
                </span>
              </p>
              <p>
                <span className="totalAvailAmt">
                  Available Contribution Amount: <span>&#8377;</span>
                  {availableContribution ? availableContribution : 0}/-
                </span>
                <span className="totalAvailAmt">
                  Available Zakath Amount: <span>&#8377;</span>{" "}
                  {availableZakath ? availableZakath : 0}
                  /-
                </span>
              </p>
            </div>
          </div>
          <div className=" total-outline col-md-3">
            <div className="total-price">
              <span className="td-total">Total :</span>
              <span className="td-price">
                <span>&#8377;</span> {props.totalAmt ? props.totalAmt : 0}/-
              </span>
            </div>
          </div>
        </div>
      )}
      {props.totalRecord == null && (
        <div className="offset-md-9">
          <div className=" total-outline col-md-12">
            <tr className="total-price">
              <td className="td-total">Total :</td>
              <td className="td-price">
                <span>&#8377;</span> {props.totalAmt ? props.totalAmt : 0}/-
              </td>
            </tr>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default TotalAmount;
