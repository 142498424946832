/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import MemberList from "../components/DataTable/MemberList";
import AddMember from "./AddMember";

class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddOrEditMember: "",
      editData: []
    };
    this.handleAddMember = this.handleAddMember.bind(this);
    this.handleOnEdit = this.handleOnEdit.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
  }

  handleAddMember(event, data) {
    event.preventDefault();
    console.log("redirectme", data);
    //this.props.history.member = "new";
    //this.props.history.push("/admin/members/addmember");
    this.setState({
      isAddOrEditMember: data
    });
  }
  handleOnEdit(userData) {
    console.log("data", userData);
    this.setState({
      isAddOrEditMember: "edit",
      editData: userData
    });
  }
  handleOnCancel() {
    this.setState({
      isAddOrEditMember: "",
      editData: []
    });
  }
  componentDidMount() {
    console.log("this.props", this.state.isAddOrEditMember);
  }
  render() {
    return (
      <>
        <div className="content">
          {this.state.isAddOrEditMember === "" && (
            <Row className="addmemberTop">
              <Col>
                <Button
                  className="btn-round pull-right"
                  color="primary"
                  type="submit"
                  onClick={e => this.handleAddMember(e, "new")}>
                  Add Member
                </Button>
              </Col>
            </Row>
          )}
          {this.state.isAddOrEditMember !== "" ? (
            <AddMember
              isAddOrEditMember={this.state.isAddOrEditMember}
              onEditMember={this.state.editData}
              onCancelMember={this.handleOnCancel}
            />
          ) : (
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">Member List</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <MemberList
                      {...this.props}
                      onEditMember={this.handleOnEdit}></MemberList>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}

export default Members;
