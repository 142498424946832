/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Expenditure.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";
import ModelPopup from "../ModelPopup/ModelPopup";

class ExpenditureForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("add new props in form", props);
    const {
      expenditureType,
      categoryName,
      date,
      amount,
      name,
      remarks,
      _id,
    } = this.props.onEditExpenditure;
    let splitDate = date ? date.split("/") : "";
    let editDate = splitDate
      ? splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]
      : "";
    console.log("editDte", editDate);
    this.state = {
      categoryName: categoryName ? categoryName : "",
      name: name ? name : "",
      date: date ? new Date(editDate) : "",
      amount: amount ? amount : "",
      remarks: remarks ? remarks : "",
      errors: {
        categoryName: "",
        name: "",
        date: "",
        amount: "",
        remarks: "",
      },
      formValid: false,
      categoryList: [
        { id: 1, name: "Madarasa Salary" },
        { id: 2, name: "Madarasa Expense" },
        { id: 3, name: "Marriage" },
        { id: 4, name: "Medical" },
        { id: 5, name: "Bayan" },
        { id: 6, name: "Others" },
      ],
      editId: _id ? _id : "",
    };
    this.child = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this);
    this.addExpeniture = this.addExpeniture.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
  }

  setErrorMessage(state) {
    const { categoryName, name, date, amount, remarks, errors } = state;
    if (categoryName === "") {
      errors.categoryName = MessageConstant.CATEGORY_REQUIRED;
    }
    if (name === "") {
      errors.name = MessageConstant.NAME_REQUIRED;
    }
    if (date === "") {
      errors.date = MessageConstant.DATE_REQUIRED;
    }
    if (amount === "") {
      errors.amount = MessageConstant.AMOUNT_REQUIRED;
    }
    if (remarks === "") {
      errors.remarks = MessageConstant.REMARKS_REQUIRED;
    }
    return errors;
  }

  componentDidMount() {}

  handleOnChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "categoryName":
        console.log("valueeee", name, value);
        errors.categoryName =
          value === "" ? MessageConstant.CATEGORY_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "date":
        console.log("valuesss", value);
        errors.date = value === "" ? MessageConstant.DATE_REQUIRED : "";
        //let date = moment(value).format('MM/DD/YYYY').toString();
        //let date = moment(value).unix();
        console.log("dateeeee", moment(value).unix());
        console.log(
          "conver date",
          moment.unix(moment(value).unix()).format("DD/MM/YYYY HH:mm")
        );
        this.setState({ [name]: value });
        break;
      case "amount":
        errors.amount = value === "" ? MessageConstant.AMOUNT_REQUIRED : "";
        const amount = /^[0-9.\b]+$/;
        if (value === "" || amount.test(value)) {
          this.setState({ [name]: value });
        }
        break;
      case "name":
        errors.name = value === "" ? MessageConstant.NAME_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "remarks":
        errors.remarks = value === "" ? MessageConstant.REMARKS_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      default:
        break;
    }
  }
  handleOnCancel() {
    this.props.onCancelExpenditure();
  }
  handleOnClear(event) {
    event.preventDefault();
    this.setState({
      categoryName: "",
      name: "",
      date: "",
      amount: "",
      remarks: "",
    });
  }
  addExpeniture(event) {
    event.preventDefault();
    let errorMessage = this.setErrorMessage(this.state);
    this.setState({ errors: errorMessage });
    this.setState({ formValid: utils.validateForm(this.state.errors) });
    console.log("this.state", this.state);
    let Type = this.props.expenditureType;
    let availableAmount = this.props.availableAmount;
    let availableContribution =
      availableAmount.totalContribution -
      availableAmount.contributionExpenditure;
    let availableZakath =
      availableAmount.totalZakath - availableAmount.zakathExpenditure;
    console.log("availableContribution", availableContribution);
    console.log("availableZakath", availableZakath);
    let avail = true;
    setTimeout(() => {
      const { formValid, categoryName, amount, name, remarks } = this.state;
      if (formValid) {
        console.log("formValid", this.state);
        console.log("on submit props", this.props);
        if (Type === "contribution") {
          if (parseFloat(amount) > availableContribution) {
            avail = false;
            this.child.current.notify(
              "tr",
              3,
              MessageConstant.ALERT_AMOUNT_CONTRIBUTION(availableContribution)
            );
          }
        }
        if (Type === "zakath") {
          if (parseFloat(amount) > availableZakath) {
            avail = false;
            this.child.current.notify(
              "tr",
              3,
              MessageConstant.ALERT_AMOUNT_ZAKATH(availableZakath)
            );
          }
        }
        if (avail) {
          let expenditureData = {
            expenditureType: this.props.expenditureType,
            categoryName: categoryName,
            date: moment(this.state.date).unix(),
            amount: amount,
            name: name,
            remarks: remarks,
          };

          console.log("expenditureData", expenditureData);
          if (this.props.isAddOrEditFlag === "new") {
            utils
              .makeRequest(
                this,
                EndpointConstant.ADD_EXPENDITURE,
                "post",
                expenditureData
              )
              .then((response) => {
                utils.hideLoader();
                let data = response.data;
                console.log("response add", response);
                this.child.current.notify("tr", 2, data.message);
                //utils.gotoPage("/admin/members");
                setTimeout(() => {
                  this.props.onCancelExpenditure();
                }, 1000);
              })
              .catch((error) => {
                utils.hideLoader();
                let err = error.response;
                console.log("errors", err);
                this.child.current.notify("tr", 3, this.handleError(err));
                const err401 = error.response;
                utils.logoutUnauthorized(err401);
              });
          } else if (this.props.isAddOrEditFlag === "edit") {
            console.log("type of", this.state.date);
            expenditureData.date = moment(this.state.date).unix();
            expenditureData.id = this.state.editId;
            utils
              .makeRequest(
                this,
                EndpointConstant.UPDATE_EXPENDITURE,
                "post",
                expenditureData
              )
              .then((response) => {
                utils.hideLoader();
                let data = response.data;
                console.log("response edit", response);
                this.child.current.notify("tr", 2, data.message);
                //utils.gotoPage("/admin/members");
                setTimeout(() => {
                  this.props.onCancelExpenditure();
                }, 1000);
              })
              .catch((error) => {
                utils.hideLoader();
                let err = error.response;
                console.log("errors", err);
                this.child.current.notify("tr", 3, err.data.message);
                const err401 = error.response;
                utils.logoutUnauthorized(err401);
              });
          } else {
            this.props.onCancelExpenditure();
          }
        } else {
          console.log("amount not valid");
        }
      } else {
        console.log("invalid form");
      }
    }, 500);
  }
  render() {
    console.log("this.state", this.state);
    console.log("this.props expenditure form", this.props);
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate());
    const { errors, categoryList } = this.state;
    const categoryOption =
      categoryList &&
      this.state.categoryList.map((data) => {
        return (
          <option key={data.id} value={data.name}>
            {data.name}
          </option>
        );
      });
    return (
      <CardBody>
        <ToasterMessage ref={this.child} />
        <div className="rowCentered">
          <Form onSubmit={this.addExpeniture}>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">Category</label>
                  <React.Fragment>
                    <Input
                      type="select"
                      name="categoryName"
                      id="categoryName"
                      value={this.state.categoryName}
                      onChange={this.handleOnChange}>
                      <option value="">Select Category</option>
                      {categoryOption}
                    </Input>
                    <p className="text-danger">{errors.categoryName}</p>
                  </React.Fragment>
                </FormGroup>
              </Col>

              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">Date</label>
                  <Calendar
                    style={{ width: "99%" }}
                    inputStyle={{ width: "94%", border: "1px solid #ddd" }}
                    dateFormat="dd/mm/yy"
                    showIcon={true}
                    value={this.state.date}
                    placeholder="Date (DD/MM/YYYY)"
                    name="date"
                    maxDate={maxDate}
                    onChange={this.handleOnChange}
                    monthNavigator
                    yearNavigator
                    yearRange="2014:2030"></Calendar>
                  <p className="text-danger">{errors.date}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">Amount</label>
                  <Input
                    placeholder="Amount"
                    type="text"
                    name="amount"
                    value={this.state.amount}
                    onChange={this.handleOnChange}
                  />
                  <p className="text-danger">{errors.amount}</p>
                </FormGroup>
              </Col>

              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">Name</label>

                  <Input
                    placeholder="Name"
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleOnChange}
                  />
                  <p className="text-danger">{errors.name}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="12">
                <FormGroup>
                  <label className="required">Remarks</label>

                  <Input
                    placeholder="Remarks"
                    type="textarea"
                    name="remarks"
                    value={this.state.remarks}
                    onChange={this.handleOnChange}
                  />
                  <p className="text-danger">{errors.remarks}</p>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <div className="update ml-auto mr-auto">
                <Button
                  className="btn-round ml-4"
                  color="primary"
                  type="submit">
                  Save
                </Button>
                <Button
                  className="btn-round ml-4"
                  color="secondary"
                  onClick={this.handleOnCancel}>
                  Cancel
                </Button>
                <Button
                  className="btn-round ml-4"
                  color="info"
                  onClick={this.handleOnClear}>
                  Clear
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </CardBody>
    );
  }
}

export default ExpenditureForm;
