const apiUrl = "/api";
const EndpointConstant = {};
EndpointConstant.LOGIN = apiUrl + "/login";
EndpointConstant.REGISTER = apiUrl + "/register";
EndpointConstant.MEMBER_LIST = apiUrl + "/userlist";
EndpointConstant.UPDATE_USER = apiUrl + "/updateuser";
EndpointConstant.USER_ACCESS = apiUrl + "/useraccess";
EndpointConstant.USER_DELETE = apiUrl + "/userdelete";
EndpointConstant.LAST_CODE_ID = apiUrl + "/lastcodeid";
EndpointConstant.USER_DETAILS = apiUrl + "/userDetails";
EndpointConstant.USER_PASSWORD_UPDATE = apiUrl + "/updatePassword";
EndpointConstant.USER_PASSWORD_RESET = apiUrl + "/resetPassword";
EndpointConstant.ADD_CONTRIBUTION = apiUrl + "/addContribution";
EndpointConstant.CONTRIBUTION_LIST = apiUrl + "/contributionList";
EndpointConstant.UPDATE_CONTRIBUTION = apiUrl + "/updateContribution";
EndpointConstant.DELETE_CONTRIBUTION = apiUrl + "/deleteContribution";
EndpointConstant.FILTER_CONTRIBUTION = apiUrl + "/filterContribution";
EndpointConstant.ADD_ZAKATH = apiUrl + "/addZakath";
EndpointConstant.ZAKATH_LIST = apiUrl + "/zakathList";
EndpointConstant.UPDATE_ZAKATH = apiUrl + "/updateZakath";
EndpointConstant.DELETE_ZAKATH = apiUrl + "/deleteZakath";
EndpointConstant.FILTER_ZAKATH = apiUrl + "/filterZakath";
EndpointConstant.ADD_EXPENDITURE = apiUrl + "/addExpenditure";
EndpointConstant.EXPENDITURE_LIST = apiUrl + "/expenditureList";
EndpointConstant.UPDATE_EXPENDITURE = apiUrl + "/updateExpenditure";
EndpointConstant.DELETE_EXPENDITURE = apiUrl + "/deleteExpenditure";
EndpointConstant.FILTER_EXPENDITURE = apiUrl + "/filterExpenditure";
EndpointConstant.DASHBOARD_COLLECTION = apiUrl + "/dashboardCollection";
EndpointConstant.DASHBOARD_EXPENSE = apiUrl + "/dashboardExpense";
EndpointConstant.DASHBOARD_MEMBERS = apiUrl + "/dashboardMembers";
EndpointConstant.SEND_EMAIL = apiUrl + "/sendEmail";
EndpointConstant.UPDATE_EMAIL = apiUrl + "/updateEmail";
export default EndpointConstant;
