/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import utils from "../utils";
import AddExpenditure from "../components/Expenditure/AddExpenditure";
import ExpenditureList from "../components/Expenditure/ExpenditureList";
import FilterExpenditure from "../components/Expenditure/FilterExpenditure";

class Expenditure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddOrEditFlag: "",
      editData: [],
      filteredData: {},
      filterReset: false,
    };
    this.handleAddZakath = this.handleAddZakath.bind(this);
    this.handleOnCancel = this.handleOnCancel.bind(this);
    this.onEditExpenditure = this.onEditExpenditure.bind(this);
    this.onFilteredData = this.onFilteredData.bind(this);
    this.onClearReset = this.onClearReset.bind(this);
  }
  handleAddZakath(event, data) {
    event.preventDefault();
    console.log("redirectme", data);
    this.setState({
      isAddOrEditFlag: data,
    });
  }
  handleOnCancel() {
    this.setState({
      isAddOrEditFlag: "",
      editData: [],
    });
  }
  onEditExpenditure(data) {
    console.log("edited data", data);
    this.setState({
      isAddOrEditFlag: "edit",
      editData: data,
    });
  }
  onFilteredData(data) {
    console.log("on filtered data", data);
    this.setState({
      filteredData: data,
    });
  }
  onClearReset(data) {
    this.setState({
      filterReset: data,
    });
  }
  render() {
    return (
      <div className="content">
        {this.state.isAddOrEditFlag === "" &&
          utils.getSessionItem("role") === "admin" && (
            <Row className="addmemberTop">
              <Col>
                <Button
                  className="btn-round pull-right"
                  color="primary"
                  type="submit"
                  onClick={(e) => this.handleAddZakath(e, "new")}>
                  Add Expenditure
                </Button>
              </Col>
            </Row>
          )}
        {this.state.isAddOrEditFlag !== "" ? (
          <AddExpenditure
            isAddOrEditFlag={this.state.isAddOrEditFlag}
            onEditExpenditure={this.state.editData}
            onCancelExpenditure={this.handleOnCancel}
          />
        ) : (
          <React.Fragment>
            <FilterExpenditure
              onFilteredData={this.onFilteredData}
              onClearReset={this.onClearReset}
            />
            <ExpenditureList
              onFilteredData={this.state.filteredData}
              onClearReset={this.state.filterReset}
              onEditExpenditure={this.onEditExpenditure}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default Expenditure;
