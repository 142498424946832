/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Expenditure.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ExpenditureForm from "./ExpenditureForm";

class AddExpenditure extends React.Component {
  constructor(props) {
    super(props);
    console.log("add new props in add/edit Zakath", props);
    const { expenditureType } = this.props.onEditExpenditure;
    this.state = {
      selectedOption: expenditureType ? expenditureType : "contribution",
      contributionZakath: {},
    };
    this.handleOnChangeMemberType = this.handleOnChangeMemberType.bind(this);
    this.onCancelExpenditure = this.onCancelExpenditure.bind(this);
  }

  componentDidMount() {
    this.getExpenditureList();
  }

  getExpenditureList() {
    utils
      .makeRequest(this, EndpointConstant.EXPENDITURE_LIST, "get")
      .then((response) => {
        utils.hideLoader();
        this.setState({
          contributionZakath: response.data.contributionZakath,
        });
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("errors", error);
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }

  handleOnChangeMemberType(event) {
    this.setState({
      selectedOption: event.target.value,
    });
  }
  onCancelExpenditure() {
    this.props.onCancelExpenditure();
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardBody className="memberFontSize">
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <Label className="form-check-label">
                          Expenditure Type
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup className="memberOption">
                        <Label className="form-check-label">
                          <Input
                            type="radio"
                            name="expenditureId"
                            id="expenditureId1"
                            value="contribution"
                            checked={
                              this.state.selectedOption === "contribution"
                            }
                            onChange={this.handleOnChangeMemberType}
                          />
                          Contribution
                          <span className="form-check-sign"></span>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup className="memberOption">
                        <Label className="form-check-label">
                          <Input
                            type="radio"
                            name="expenditureId"
                            id="expenditureId2"
                            value="zakath"
                            checked={this.state.selectedOption === "zakath"}
                            onChange={this.handleOnChangeMemberType}
                          />
                          Zakath
                          <span className="form-check-sign"></span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">
                    {this.props.isAddOrEditFlag === "new"
                      ? "Add Expenditure"
                      : "Edit Expenditure"}
                  </CardTitle>
                </CardHeader>
                <ExpenditureForm
                  expenditureType={this.state.selectedOption}
                  isAddOrEditFlag={this.props.isAddOrEditFlag}
                  onEditExpenditure={this.props.onEditExpenditure}
                  onCancelExpenditure={this.onCancelExpenditure}
                  availableAmount={this.state.contributionZakath}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AddExpenditure;
