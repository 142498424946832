/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
//import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.jsx";
import Barcharts from "../components/Charts/Barcharts";
import utils from "../utils";
import EndpointConstant from "../constants/EndpointConstant";
import moment from "moment";
class Dashboard extends React.Component {
  constructor(props) {
    const date = new Date();
    const currentYear = date.getFullYear();
    super(props);
    this.state = {
      collectionYear: currentYear,
      expenseYear: currentYear,
      memberYear: currentYear,
      collectionContribution: [],
      collectionZakath: [],
      expenseContribution: [],
      expenseZakath: [],
      totalRecords: {},
      memberDetails: [],
    };
    this.handleCollectionChange = this.handleCollectionChange.bind(this);
    this.handleExpenseChange = this.handleExpenseChange.bind(this);
    this.handleMemberCollection = this.handleMemberCollection.bind(this);
  }
  handleCollectionChange(e) {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    this.setState(
      {
        collectionYear: value,
      },
      () => {
        this.getCollectionDetails(this.state.collectionYear, 1);
      }
    );
  }
  handleExpenseChange(e) {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    this.setState(
      {
        expenseYear: value,
      },
      () => {
        this.getCollectionDetails(this.state.expenseYear, 2);
      }
    );
  }
  handleMemberCollection(e) {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);
    this.setState(
      {
        memberYear: value,
      },
      () => {
        this.getCollectionDetails(this.state.memberYear, 3);
      }
    );
  }
  componentDidMount() {
    this.getAmountDetails();
    this.getCollectionDetails(this.state.collectionYear, 1);
    this.getCollectionDetails(this.state.expenseYear, 2);
    this.getCollectionDetails(this.state.memberYear, 3);
  }

  getAmountDetails() {
    utils
      .makeRequest(this, EndpointConstant.EXPENDITURE_LIST, "get")
      .then((response) => {
        utils.hideLoader();
        this.setState({
          totalRecords: response.data.contributionZakath,
        });
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("errors", error);
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }

  getCollectionDetails(year, flag) {
    let data = { year: year };
    if (flag == 1) {
      utils
        .makeRequest(this, EndpointConstant.DASHBOARD_COLLECTION, "post", data)
        .then((response) => {
          console.log("resonse", response);
          utils.hideLoader();
          this.setState({
            collectionContribution: response.data.contribution,
            expenseContribution: response.data.contributionExpenditure,
          });
        })
        .catch((error) => {
          utils.hideLoader();
          console.log("errors", error);
          const err401 = error.response;
          utils.logoutUnauthorized(err401);
        });
    }
    if (flag == 2) {
      utils
        .makeRequest(this, EndpointConstant.DASHBOARD_EXPENSE, "post", data)
        .then((response) => {
          console.log("resonse expense", response);
          utils.hideLoader();
          this.setState({
            collectionZakath: response.data.zakath,
            expenseZakath: response.data.zakathExpenditure,
          });
        })
        .catch((error) => {
          utils.hideLoader();
          console.log("errors", error);
          const err401 = error.response;
          utils.logoutUnauthorized(err401);
        });
    }
    if (flag == 3) {
      utils
        .makeRequest(this, EndpointConstant.DASHBOARD_MEMBERS, "post", data)
        .then((response) => {
          console.log("resonse member collection", response);
          utils.hideLoader();
          response.data.sort((a, b) => a.code.localeCompare(b.code));
          this.setState({
            memberDetails: response.data,
          });
        })
        .catch((error) => {
          utils.hideLoader();
          console.log("errors", error);
          const err401 = error.response;
          utils.logoutUnauthorized(err401);
        });
    }
  }

  render() {
    const {
      collectionContribution,
      collectionZakath,
      expenseContribution,
      expenseZakath,
      totalRecords,
    } = this.state;
    const date = new Date();
    const currentYear = date.getFullYear();
    const year = [];
    let availableContribution = 0;
    let availableZakath = 0;
    for (var i = currentYear; i >= 2014; i--) {
      year.push(i);
    }
    const yearOptions = year.map((yr) => {
      return (
        <option key={yr} value={yr}>
          {yr}
        </option>
      );
    });

    if (
      totalRecords.totalContribution &&
      totalRecords.contributionExpenditure
    ) {
      availableContribution =
        totalRecords.totalContribution - totalRecords.contributionExpenditure;
    }

    if (totalRecords.totalZakath && totalRecords.zakathExpenditure) {
      availableZakath =
        totalRecords.totalZakath - totalRecords.zakathExpenditure;
    }

    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-handshake text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Contribution</p>
                        <CardTitle tag="p">
                          <span>&#8377;</span>{" "}
                          {totalRecords.totalContribution
                            ? totalRecords.totalContribution
                            : 0}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-handshake" /> Total Contribution
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Expenditure</p>
                        <CardTitle tag="p">
                          <span>&#8377;</span>{" "}
                          {totalRecords.contributionExpenditure
                            ? totalRecords.contributionExpenditure
                            : 0}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-money-bill-alt" /> Balance:{" "}
                    <strong className="balanceAmt">
                      <span>&#8377;</span> {availableContribution}/-
                    </strong>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-hands text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Zakath</p>
                        <CardTitle tag="p">
                          <span>&#8377;</span>{" "}
                          {totalRecords.totalZakath
                            ? totalRecords.totalZakath
                            : 0}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-hands" /> Total Zakath
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fas fa-money-bill-alt text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Expenditure</p>
                        <CardTitle tag="p">
                          <span>&#8377;</span>{" "}
                          {totalRecords.zakathExpenditure
                            ? totalRecords.zakathExpenditure
                            : 0}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-money-bill-alt" /> Balance:
                    <strong className="balanceAmt">
                      <span>&#8377;</span> {availableZakath}/-
                    </strong>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    Contribution / Contribution Expenditure
                    <span className="pull-right">
                      <select
                        name="collections"
                        onChange={this.handleCollectionChange}>
                        {yearOptions}
                      </select>
                    </span>
                  </CardTitle>
                  <p className="card-category">
                    The amount of contribution received and contribution
                    expenses for the selected year.
                  </p>
                </CardHeader>
                <CardBody>
                  {/* <Line
                    data={dashboard24HoursPerformanceChart.data}
                    options={dashboard24HoursPerformanceChart.options}
                    width={400}
                    height={100}
                  /> */}
                  <Barcharts
                    contribution={collectionContribution}
                    zakath={expenseContribution}
                    labels={{
                      label1: "Contribution Received",
                      label2: "Contribution Expense",
                    }}
                    colors={{ color1: "#42A5F5", color2: "#9CCC65" }}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Latest updated records
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    Zakath / Zakath Expenditure
                    <span className="pull-right">
                      <select
                        name="expense"
                        onChange={this.handleExpenseChange}>
                        {yearOptions}
                      </select>
                    </span>
                  </CardTitle>
                  <p className="card-category">
                    The amount of zakath received and zakath expenses for the
                    selected year.
                  </p>
                </CardHeader>
                <CardBody>
                  {/* <Line
                    data={dashboard24HoursPerformanceChart.data}
                    options={dashboard24HoursPerformanceChart.options}
                    width={400}
                    height={100}
                  /> */}
                  <Barcharts
                    contribution={collectionZakath}
                    zakath={expenseZakath}
                    labels={{
                      label1: "Zakath Received",
                      label2: "Zakath Expense",
                    }}
                    colors={{ color1: "#42A5F5", color2: "#9CCC65" }}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Latest updated records
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    Members Contribution Details
                    <span className="pull-right">
                      <select
                        name="expense"
                        onChange={this.handleMemberCollection}>
                        {yearOptions}
                      </select>
                    </span>
                  </CardTitle>
                  <p className="card-category">
                    Contribution details of each members for the selected year
                  </p>
                </CardHeader>
                <CardBody>
                  {/* <Line
                    data={dashboard24HoursPerformanceChart.data}
                    options={dashboard24HoursPerformanceChart.options}
                    width={400}
                    height={100}
                  /> */}
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Jan</th>
                        <th>Feb</th>
                        <th>Mar</th>
                        <th>Apr</th>
                        <th>May</th>
                        <th>June</th>
                        <th>July</th>
                        <th>Aug</th>
                        <th>Sep</th>
                        <th>Oct</th>
                        <th>Nov</th>
                        <th>Dec</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.memberDetails.length > 0 &&
                        this.state.memberDetails.map((d) => {
                          return (
                            <tr key={d.code}>
                              <th scope="row">{d.code}</th>
                              <td
                                style={{
                                  background:
                                    d.jan < 0
                                      ? ""
                                      : d.jan > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.feb < 0
                                      ? ""
                                      : d.feb > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.mar < 0
                                      ? ""
                                      : d.mar > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.apr < 0
                                      ? ""
                                      : d.apr > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.may < 0
                                      ? ""
                                      : d.may > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.june < 0
                                      ? ""
                                      : d.june > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.july < 0
                                      ? ""
                                      : d.july > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.aug < 0
                                      ? ""
                                      : d.aug > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.sept < 0
                                      ? ""
                                      : d.sept > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.oct < 0
                                      ? ""
                                      : d.oct > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.nov < 0
                                      ? ""
                                      : d.nov > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                              <td
                                style={{
                                  background:
                                    d.dec < 0
                                      ? ""
                                      : d.dec > 0
                                      ? "green"
                                      : "red",
                                }}></td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Latest updated records
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col md="4">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Email Statistics</CardTitle>
                  <p className="card-category">Last Campaign Performance</p>
                </CardHeader>
                <CardBody>
                  <Pie
                    data={dashboardEmailStatisticsChart.data}
                    options={dashboardEmailStatisticsChart.options}
                  />
                </CardBody>
                <CardFooter>
                  <div className="legend">
                    <i className="fa fa-circle text-primary" /> Opened{" "}
                    <i className="fa fa-circle text-warning" /> Read{" "}
                    <i className="fa fa-circle text-danger" /> Deleted{" "}
                    <i className="fa fa-circle text-gray" /> Unopened
                  </div>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-calendar" /> Number of emails sent
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col md="8">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">NASDAQ: AAPL</CardTitle>
                  <p className="card-category">Line Chart with Points</p>
                </CardHeader>
                <CardBody>
                  <Line
                    data={dashboardNASDAQChart.data}
                    options={dashboardNASDAQChart.options}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter>
                  <div className="chart-legend">
                    <i className="fa fa-circle text-info" /> Tesla Model S{" "}
                    <i className="fa fa-circle text-warning" /> BMW 5 Series
                  </div>
                  <hr />
                  <div className="card-stats">
                    <i className="fa fa-check" /> Data information certified
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row> */}
        </div>
      </>
    );
  }
}

export default Dashboard;
