import React, { Component } from "react";
import { Chart } from "primereact/chart";

class BarChartDemo extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: this.props.labels.label1,
          backgroundColor: this.props.colors.color1,
          data: this.props.contribution,
        },
        {
          label: this.props.labels.label2,
          backgroundColor: this.props.colors.color2,
          data: this.props.zakath,
        },
      ],
    };

    return (
      <div>
        <Chart type="bar" data={data} />
      </div>
    );
  }
}
export default BarChartDemo;
