/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Userprofile.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";
import { InputSwitch } from "primereact/inputswitch";

class Userprofile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowForm: false,
      formValid: false,
      newpassword: "",
      confirmpassword: "",
      errors: { newpassword: "", confirmpassword: "", unmatchpassword: "" },
      userdetails: {},
      isEditing: false,
      email: "",
    };
    this.child = React.createRef();
    this.showHideForm = this.showHideForm.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.handleOnEditClick = this.handleOnEditClick.bind(this);
    this.handleOnEmailChange = this.handleOnEmailChange.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
  }

  componentDidMount() {
    this.getMemberDetails();
  }
  showHideForm(e) {
    console.log("e", e);
    this.setState({
      isShowForm: e.value,
    });
  }
  handleOnChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "newpassword":
        errors.newpassword =
          value === "" ? MessageConstant.NEW_PASSWORD_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "confirmpassword":
        errors.confirmpassword =
          value === "" ? MessageConstant.CONFIRM_PASSWORD_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      default:
        break;
    }
  }
  setErrorMessage(state) {
    console.log("sdfsfsdfsdf", state);
    const { newpassword, confirmpassword, errors } = state;
    if (newpassword == "") {
      errors.newpassword = MessageConstant.NEW_PASSWORD_REQUIRED;
    }
    if (confirmpassword == "") {
      errors.confirmpassword = MessageConstant.CONFIRM_PASSWORD_REQUIRED;
    }
    if (newpassword != confirmpassword) {
      errors.unmatchpassword = MessageConstant.UNMATCHED_PASSWORD;
      if (newpassword != "" && confirmpassword != "") {
        this.child.current.notify("tr", 3, MessageConstant.UNMATCHED_PASSWORD);
      }
    } else {
      errors.unmatchpassword = "";
    }
    console.log("error", errors);
    return errors;
  }
  updatePassword(e) {
    e.preventDefault();
    const { formValid } = this.state;
    let errorMessage = this.setErrorMessage(this.state);
    this.setState({ errors: errorMessage }, () => {
      this.setState(
        { formValid: utils.validateForm(this.state.errors) },
        () => {
          this.callServiceToUpdate(this.state);
        }
      );
    });
  }
  callServiceToUpdate(state) {
    const { formValid, newpassword } = state;
    console.log("this.state", state);
    //setTimeout(() => {
    if (formValid) {
      console.log("form Valid");
      let data = {
        password: newpassword,
      };
      utils
        .makeRequest(this, EndpointConstant.USER_PASSWORD_UPDATE, "post", data)
        .then((response) => {
          utils.hideLoader();
          let data = response.data;
          console.log("response updated password", response);
          this.child.current.notify("tr", 2, data.message);
          utils.gotoPage("/login");
        })
        .catch((error) => {
          utils.hideLoader();
          let err = error.response;
          console.log("errors", err);
          const err401 = error.response;
          utils.logoutUnauthorized(err401);
        });
    } else {
      console.log("form invalid");
    }
    //}, 1000);
  }
  getMemberDetails() {
    utils
      .makeRequest(this, EndpointConstant.USER_DETAILS, "get")
      .then((response) => {
        utils.hideLoader();
        console.log("getmemeber", response);
        this.setState({
          userdetails: response.data,
          email: response.data.email,
        });
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("err", JSON.stringify(error.response));
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }

  handleOnEditClick(e) {
    e.preventDefault();
    this.setState({
      isEditing: true,
    });
  }

  handleOnEmailChange(event) {
    const { value } = event.target;
    this.setState({
      email: value,
    });
  }

  handleOnSave(e) {
    e.preventDefault();
    const { email, userdetails } = this.state;
    console.log("email", this.state.email);
    let emailFormat =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(email);
    if (email == "" || email == null || email == undefined) {
      this.child.current.notify("tr", 3, MessageConstant.EMAIL_REQUIRED);
    } else if (emailFormat == false) {
      this.child.current.notify("tr", 3, MessageConstant.INVALID_EMAIL_ID);
    } else if (email == userdetails.email) {
      this.child.current.notify("tr", 3, MessageConstant.SAME_EMAIL_ID);
    } else {
      let data = {
        id: userdetails._id,
        email: email,
      };
      console.log("valid email", data);
      utils
        .makeRequest(this, EndpointConstant.UPDATE_EMAIL, "post", data)
        .then((response) => {
          utils.hideLoader();
          this.setState({
            isEditing: false,
          });
          let data = response.data;
          console.log("response updated email", response);
          this.child.current.notify("tr", 2, data.message);
          this.getMemberDetails();
        })
        .catch((error) => {
          utils.hideLoader();
          let err = error.response;
          console.log("errors", err);
          const err401 = error.response;
          utils.logoutUnauthorized(err401);
        });
    }
  }

  handleOnClose(e) {
    e.preventDefault();
    this.setState({
      email: this.state.userdetails.email,
      isEditing: false,
    });
  }
  render() {
    const { errors, userdetails } = this.state;
    return (
      <Card className="cardRoot">
        <CardBody className="filteredBody">
          <CardTitle className="cardTitle">User Profile</CardTitle>
          <ToasterMessage ref={this.child} />
          <Row>
            <Col md="11">
              <CardBody className="userBody">
                <Form onSubmit={this.updatePassword}>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Code</label>
                        <p>{userdetails.code}</p>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Name</label>
                        <p>{userdetails.name}</p>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Role</label>
                        <p>{userdetails.type}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1 email-edit" md="4">
                      <FormGroup>
                        <label>Email</label>
                        <p>
                          {!this.state.isEditing ? (
                            userdetails.email
                          ) : (
                            <Input
                              type="text"
                              name="email"
                              id="email"
                              value={this.state.email}
                              className="col-md-9 userEmail"
                              style={{ display: "inline" }}
                              onChange={this.handleOnEmailChange}></Input>
                          )}
                          {!this.state.isEditing ? (
                            <span className="pl-1 pr-1">
                              <i
                                className="fas fa-pencil-alt"
                                onClick={this.handleOnEditClick}></i>
                            </span>
                          ) : (
                            <React.Fragment>
                              <span className="pl-1 pr-1">
                                <i
                                  className="fas fa-check-circle"
                                  onClick={this.handleOnSave}></i>
                              </span>
                              <span className="pr-1">
                                <i
                                  className="fas fa-times-circle"
                                  onClick={this.handleOnClose}></i>
                              </span>
                            </React.Fragment>
                          )}
                        </p>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Mobile</label>
                        <p>{userdetails.mobile}</p>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Address</label>
                        <p>
                          {userdetails.address
                            ? userdetails.address
                                .split(",")
                                .map((item, key) => {
                                  return (
                                    <span key={key}>
                                      {item}
                                      <br />
                                    </span>
                                  );
                                })
                            : ""}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label className="updateText">
                          Do you want to update your password?
                        </label>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <InputSwitch
                          checked={this.state.isShowForm}
                          onChange={this.showHideForm}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.isShowForm && (
                    <React.Fragment>
                      <Row>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>New Password </label>
                            <Input
                              placeholder="New Password"
                              type="password"
                              name="newpassword"
                              onChange={this.handleOnChange}
                            />
                            <p className="text-danger">{errors.newpassword}</p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Confirm Password</label>
                            <Input
                              placeholder="Confirm Password"
                              type="password"
                              name="confirmpassword"
                              onChange={this.handleOnChange}
                            />
                            <p className="text-danger">
                              {errors.confirmpassword}
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="4">
                          <div className="ml-auto mr-auto">
                            <Button
                              className="btn"
                              color="primary"
                              type="submit">
                              Update Password
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Form>
              </CardBody>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default Userprofile;
