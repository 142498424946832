/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Zakath.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ZakathForm from "./ZakathForm";

class AddZakath extends React.Component {
  constructor(props) {
    super(props);
    console.log("add new props in add/edit Zakath", props);
    const { memberType } = this.props.onEditZakath;
    this.state = {
      selectedOption: memberType ? memberType : "member",
    };
    this.handleOnChangeMemberType = this.handleOnChangeMemberType.bind(this);
    this.onCancelZakath = this.onCancelZakath.bind(this);
  }

  componentDidMount() {}

  handleOnChangeMemberType(event) {
    this.setState({
      selectedOption: event.target.value,
    });
  }
  onCancelZakath() {
    this.props.onCancelZakath();
  }

  render() {
    return (
      <>
        <div className="content">
          {this.props.isAddOrEditFlag === "new" && (
            <Row>
              <Col md="12">
                <Card>
                  <CardBody className="memberFontSize">
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <Label className="form-check-label">
                            Member Type
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="4">
                        <FormGroup className="memberOption">
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="memberId"
                              id="memberId1"
                              value="member"
                              checked={this.state.selectedOption === "member"}
                              onChange={this.handleOnChangeMemberType}
                            />
                            Member
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="4">
                        <FormGroup className="memberOption">
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="memberId"
                              id="memberId2"
                              value="guest"
                              checked={this.state.selectedOption === "guest"}
                              onChange={this.handleOnChangeMemberType}
                            />
                            Guest
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">
                    {this.props.isAddOrEditFlag === "new"
                      ? "Add Zakath"
                      : "Edit Zakath"}
                  </CardTitle>
                </CardHeader>
                <ZakathForm
                  memberType={this.state.selectedOption}
                  isAddOrEditFlag={this.props.isAddOrEditFlag}
                  onEditZakath={this.props.onEditZakath}
                  onCancelZakath={this.onCancelZakath}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AddZakath;
