import React from "react";
import Loader from "react-loader-spinner";

function SpinnerLoader() {
  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  };
  return (
    <div style={style}>
      <Loader type="Oval" color="#2c2c2c" height={100} width={100} />
    </div>
  );
}

export default SpinnerLoader;
