/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Settings.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValid: false,
      member: "",
      newpassword: "",
      confirmpassword: "",
      errors: {
        member: "",
        newpassword: "",
        confirmpassword: "",
        unmatchpassword: "",
      },
      userdetails: {},
    };
    this.child = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  componentDidMount() {}

  handleOnChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "member":
        errors.member = value === "" ? MessageConstant.MEMBER_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "newpassword":
        errors.newpassword =
          value === "" ? MessageConstant.NEW_PASSWORD_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "confirmpassword":
        errors.confirmpassword =
          value === "" ? MessageConstant.CONFIRM_PASSWORD_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      default:
        break;
    }
  }
  setErrorMessage(state) {
    console.log("sdfsfsdfsdf", state);
    const { member, newpassword, confirmpassword, errors } = state;
    if (member == "") {
      errors.member = MessageConstant.MEMBER_REQUIRED;
    }
    if (newpassword == "") {
      errors.newpassword = MessageConstant.NEW_PASSWORD_REQUIRED;
    }
    if (confirmpassword == "") {
      errors.confirmpassword = MessageConstant.CONFIRM_PASSWORD_REQUIRED;
    }
    if (newpassword != confirmpassword) {
      errors.unmatchpassword = MessageConstant.UNMATCHED_PASSWORD;
      if (newpassword != "" && confirmpassword != "") {
        this.child.current.notify("tr", 3, MessageConstant.UNMATCHED_PASSWORD);
      }
    } else {
      errors.unmatchpassword = "";
    }
    console.log("error", errors);
    return errors;
  }
  updatePassword(e) {
    e.preventDefault();
    const { formValid } = this.state;
    let errorMessage = this.setErrorMessage(this.state);
    this.setState({ errors: errorMessage }, () => {
      this.setState(
        { formValid: utils.validateForm(this.state.errors) },
        () => {
          this.callServiceToUpdate(this.state);
        }
      );
    });
  }
  callServiceToUpdate(state) {
    const { formValid, newpassword, member } = state;
    console.log("this.state", state);
    //setTimeout(() => {
    if (formValid) {
      console.log("form Valid");
      let data = {
        email: member,
        password: newpassword,
      };
      utils
        .makeRequest(this, EndpointConstant.USER_PASSWORD_RESET, "post", data)
        .then((response) => {
          utils.hideLoader();
          let data = response.data;
          console.log("response updated password", response);
          this.child.current.notify("tr", 2, data.message);
        })
        .catch((error) => {
          utils.hideLoader();
          let err = error.response;
          console.log("errors", err);
          const err401 = error.response;
          utils.logoutUnauthorized(err401);
        });
    } else {
      console.log("form invalid");
    }
    //}, 1000);
  }

  render() {
    const { errors } = this.state;
    const memberOption =
      this.props.users &&
      this.props.users.map((data) => {
        return (
          <option key={data.code} value={data.email}>
            {data.email}
          </option>
        );
      });
    return (
      <Row>
        <ToasterMessage ref={this.child} />
        <Col md="11">
          <CardBody className="userBody">
            <Form onSubmit={this.updatePassword}>
              <Row>
                <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Member List</label>
                    <Input
                      type="select"
                      name="member"
                      id="member"
                      value={this.state.member}
                      onChange={this.handleOnChange}>
                      <option value="">Select Name</option>
                      {memberOption}
                    </Input>
                    <p className="text-danger">{errors.member}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>New Password</label>
                    <Input
                      placeholder="New Password"
                      type="password"
                      name="newpassword"
                      onChange={this.handleOnChange}
                    />
                    <p className="text-danger">{errors.newpassword}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Confirm Password</label>
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      name="confirmpassword"
                      onChange={this.handleOnChange}
                    />
                    <p className="text-danger">{errors.confirmpassword}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="6">
                  <div className="updatePwd ml-auto mr-auto">
                    <Button className="btn" color="primary" type="submit">
                      Reset Password
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Col>
      </Row>
    );
  }
}

export default ResetPassword;
