import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";

import utils from "./../../utils";
import EndpointConstant from "./../../constants/EndpointConstant";
import MessageConstant from "./../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";
import ModelPopup from "../ModelPopup/ModelPopup";

class MemberList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      showModel: false,
      dataOnModelConfirm: {},
      showDeleteModel: false,
      dataConfirmOnDelete: {},
    };
    this.actionTemplate = this.actionTemplate.bind(this);
    this.switchTemplate = this.switchTemplate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.onActivateDeactivate = this.onActivateDeactivate.bind(this);
    this.handleOnConfirmation = this.handleOnConfirmation.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCloseOnDelete = this.handleCloseOnDelete.bind(this);
    this.child = React.createRef();
    console.log("props", props);
  }

  handleEdit(e, rowData) {
    console.log("handleEdit", rowData);
    this.props.onEditMember(rowData);
  }

  handleOnConfirmation() {
    this.handleOnClose();
    utils
      .makeRequest(
        this,
        EndpointConstant.USER_ACCESS,
        "post",
        this.state.dataOnModelConfirm
      )
      .then((response) => {
        utils.hideLoader();
        let data = response.data;
        console.log("response add", response);
        this.child.current.notify("tr", 2, data.message);
        this.getMemberList();
      })
      .catch((error) => {
        utils.hideLoader();
        let err = error.response;
        console.log("errors", err);
        this.child.current.notify("tr", 3, this.handleError(err));
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }

  handleOnClose() {
    this.setState({
      showModel: false,
    });
  }
  onActivateDeactivate(e, rowData) {
    console.log("e", e);
    console.log("rowData activae", rowData);
    let data = {
      id: rowData._id,
      isActive: e.value,
    };
    this.setState({
      showModel: true,
      dataOnModelConfirm: data,
    });
  }
  onDeleteClick(e, rowData) {
    let data = {
      id: rowData._id,
    };
    this.setState({
      showDeleteModel: true,
      dataConfirmOnDelete: data,
    });
  }
  handleDelete() {
    this.handleCloseOnDelete();
    utils.showLoader();
    utils
      .makeRequest(
        this,
        EndpointConstant.USER_DELETE,
        "post",
        this.state.dataConfirmOnDelete
      )
      .then((response) => {
        utils.hideLoader();
        let data = response.data;
        console.log("response add", response);
        this.child.current.notify("tr", 2, data.message);
        this.getMemberList();
      })
      .catch((error) => {
        utils.hideLoader();
        let err = error.response;
        console.log("errors", err);
        this.child.current.notify("tr", 3, this.handleError(err));
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }

  handleCloseOnDelete() {
    this.setState({
      showDeleteModel: false,
    });
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-warning"
          style={{ marginRight: ".5em" }}
          onClick={(e) => this.handleEdit(e, rowData)}></Button>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={(e) => this.onDeleteClick(e, rowData)}></Button>
      </div>
    );
  }

  switchTemplate(rowData) {
    return (
      <div>
        <InputSwitch
          checked={rowData.isActive}
          onChange={(e) => this.onActivateDeactivate(e, rowData)}
        />
      </div>
    );
  }

  componentDidMount() {
    this.getMemberList();
  }

  getMemberList() {
    utils
      .makeRequest(
        this,
        EndpointConstant.MEMBER_LIST,
        "get",
        {},
        { option: "list" }
      )
      .then((response) => {
        utils.hideLoader();
        this.setState({
          users: response.data.user,
        });
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("err", JSON.stringify(error.response));
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }
  render() {
    //let paginatorLeft = <Button icon="pi pi-refresh" />;
    //let paginatorRight = <Button icon="pi pi-cloud-upload" />;
    const columns = [
      { field: "code", header: "Code" },
      { field: "name", header: "Name" },
      { field: "email", header: "Email" },
      { field: "mobile", header: "Mobile" },
      { field: "type", header: "Type" },
      { field: "isActive", header: "Access" },
      { field: "action", header: "Action" },
    ];

    const dynamicColumns = columns.map((col, i) => {
      let template =
        col.field === "action" ? (
          <Column
            key={col.field}
            body={this.actionTemplate}
            style={{ textAlign: "center", width: "8em" }}
          />
        ) : col.field === "isActive" ? (
          <Column
            key={col.field}
            header={col.header}
            body={this.switchTemplate}
            style={{ textAlign: "center", width: "8em" }}
          />
        ) : (
          <Column key={col.field} field={col.field} header={col.header} />
        );
      return template;
    });
    return (
      <div>
        <ToasterMessage ref={this.child} />
        <ModelPopup
          isOpen={this.state.showModel}
          handleOnClose={this.handleOnClose}
          Message={MessageConstant.CONFIRM_ACTIVE_DEACTIVE}
          handleOnConfirmation={this.handleOnConfirmation}
        />
        <ModelPopup
          isOpen={this.state.showDeleteModel}
          handleOnClose={this.handleCloseOnDelete}
          Message={MessageConstant.CONFIRM_DELETE_USER}
          handleOnConfirmation={this.handleDelete}
        />
        <DataTable
          value={this.state.users}
          paginator={true}
          responsive={true}
          header="Member"
          /*  paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight} */
          rows={5}>
          {dynamicColumns}
        </DataTable>
      </div>
    );
  }
}

export default MemberList;
