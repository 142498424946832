/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Settings.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";
//import { InputSwitch } from "primereact/inputswitch";
import { Editor } from "primereact/editor";
import { MultiSelect } from "primereact/multiselect";

class SendEmailFromEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMembers: [],
      subject: "",
      editorText: null,
    };
    this.child = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onSendEmail = this.onSendEmail.bind(this);
    this.validate = this.validate.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  componentDidMount() {}

  handleOnChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }
  onSendEmail(event) {
    event.preventDefault();
    console.log("this.state", this.state);
    const { selectedMembers, subject, editorText } = this.state;
    if (!this.validate()) {
      this.child.current.notify("tr", 3, MessageConstant.ALL_FIELD_REQUIRED);
    } else {
      console.log("Fields are valid");
      let data = {
        members: JSON.stringify(selectedMembers),
        subject: subject,
        message: editorText,
      };
      utils
        .makeRequest(this, EndpointConstant.SEND_EMAIL, "post", data)
        .then((response) => {
          utils.hideLoader();
          this.clear();
          let data = response.data;
          console.log("response updated password", response);
          if (data.hasOwnProperty("success")) {
            if (data.success == true) {
              this.child.current.notify("tr", 2, data.message);
            } else {
              this.child.current.notify("tr", 3, data.message);
            }
          }
        })
        .catch((error) => {
          utils.hideLoader();
          this.clear();
          let err = error.response;
          console.log("errors", err);
          const err401 = error.response;
          utils.logoutUnauthorized(err401);
        });
    }
  }
  validate() {
    const { selectedMembers, editorText, subject } = this.state;
    let isValid = false;
    if (selectedMembers.length > 0 && editorText != null && subject != "") {
      isValid = true;
    }
    return isValid;
  }

  clear() {
    this.setState({
      selectedMembers: [],
      subject: "",
      editorText: null,
    });
  }

  onEditorChange(e) {
    console.log("ddd", e);
    this.setState({ editorText: e.htmlValue });
  }

  render() {
    const { users } = this.props;
    const memberEmailList = users.map((data) => {
      let email = {};
      email.label = data.email;
      email.value = data.email;
      return email;
    });
    return (
      <React.Fragment>
        <ToasterMessage ref={this.child} />
        <div className="rowCentered">
          <Form onSubmit={this.onSendEmail}>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">To</label>
                  <MultiSelect
                    placeholder="Members Email List"
                    value={this.state.selectedMembers}
                    options={memberEmailList}
                    name="selectedMembers"
                    className="memberEmail"
                    onChange={this.handleOnChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <label className="required">Subject</label>
                  <Input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    autoComplete="off"
                    value={this.state.subject}
                    onChange={this.handleOnChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <CardBody className="editorBody">
                  <FormGroup>
                    <label className="required">Body</label>
                  </FormGroup>
                  <Editor
                    style={{ height: "320px" }}
                    value={this.state.text}
                    name="editorText"
                    onTextChange={this.onEditorChange}
                  />
                </CardBody>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  className="btn-primary ml-4 pull-right"
                  color="primary"
                  disabled={!this.validate()}
                  type="submit">
                  Send Email
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default SendEmailFromEditor;
