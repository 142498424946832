/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import utils from "./../utils";
import EndpointConstant from "./../constants/EndpointConstant";
import MessageConstant from "./../constants/MessageConstant";
import ToasterMessage from "./../components/Notification/ToasterMessage";

class AddMember extends React.Component {
  constructor(props) {
    super(props);
    console.log("add new props", props);
    const {
      _id,
      code,
      name,
      email,
      mobile,
      type,
      address,
    } = this.props.onEditMember;
    this.state = {
      code: code ? code : 0,
      name: name ? name : "",
      password: "",
      email: email ? email : "",
      mobile: mobile ? mobile : "",
      type: type ? type : "",
      address: address ? address : "",
      errors: {
        name: "",
        code: "",
        password: "",
        email: "",
        mobile: "",
        type: "",
      },
      formValid: false,
    };
    this.child = React.createRef();
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.addMember = this.addMember.bind(this);
  }

  setErrorMessage(state) {
    const { mobile, password, name, code, email, type, errors } = state;
    if (mobile === "") {
      errors.mobile = MessageConstant.MOBILE_REQUIRED;
    }
    if (password === "" && this.props.isAddOrEditMember === "new") {
      errors.password = MessageConstant.PASSWORD_REQUIRED;
    }
    if (name === "") {
      errors.name = MessageConstant.NAME_REQUIRED;
    }
    if (code === "") {
      errors.code = MessageConstant.CODE_REQUIRED;
    }
    if (email === "") {
      errors.email = MessageConstant.EMAIL_REQUIRED;
    }
    if (type === "") {
      errors.type = MessageConstant.TYPE_REQUIRED;
    }
    return errors;
  }

  componentDidMount() {
    if (this.props.isAddOrEditMember === "new") {
      this.getTotalMemberCount();
    }
    console.log("add new props", this.props);
  }

  perPendNum(n) {
    return n > 9 ? "0" + n : "00" + n;
  }

  getTotalMemberCount() {
    utils
      .makeRequest(this, EndpointConstant.LAST_CODE_ID, "get")
      .then((response) => {
        utils.hideLoader();
        console.log("resonse", response.data.lastCode);
        let strCode = response.data.lastCode;
        let finalStr = this.perPendNum(parseFloat(strCode.substr(3)) + 1);
        this.setState({
          code: "ALN" + finalStr,
        });
      })
      .catch((error) => {
        utils.hideLoader();
        console.log("errors", error);
        const err401 = error.response;
        utils.logoutUnauthorized(err401);
      });
  }

  handleCancel(event) {
    event.preventDefault();
    //delete this.props.history["member"];
    // utils.gotoPage("/admin/members");
    this.props.onCancelMember();
  }
  handleOnChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;
    switch (name) {
      case "mobile":
        errors.mobile = value === "" ? MessageConstant.MOBILE_REQUIRED : "";
        const mobile = /^[0-9\b]+$/;
        if ((value === "" || mobile.test(value)) && value.length <= 10) {
          this.setState({ [name]: value });
        }
        break;
      case "password":
        errors.password =
          this.props.isAddOrEditMember === "new" && value === ""
            ? MessageConstant.PASSWORD_REQUIRED
            : "";
        this.setState({ [name]: value });
        break;
      case "code":
        errors.code = value === "" ? MessageConstant.CODE_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "name":
        errors.name = value === "" ? MessageConstant.NAME_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "email":
        errors.email = value === "" ? MessageConstant.EMAIL_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "type":
        errors.type = value === "" ? MessageConstant.TYPE_REQUIRED : "";
        this.setState({ [name]: value });
        break;
      case "address":
        this.setState({
          [name]: value,
        });
        break;
      default:
        break;
    }
  }
  addMember(event) {
    event.preventDefault();
    let errorMessage = this.setErrorMessage(this.state);
    this.setState({ errors: errorMessage });
    this.setState({ formValid: utils.validateForm(this.state.errors) });
    console.log("this.state", this.state);
    setTimeout(() => {
      const { formValid } = this.state;
      if (formValid) {
        if (this.props.isAddOrEditMember === "new") {
          let data = this.state;
          console.log("valid fields");
          utils
            .makeRequest(this, EndpointConstant.REGISTER, "post", data)
            .then((response) => {
              utils.hideLoader();
              let data = response.data;
              console.log("response add", response);
              this.child.current.notify("tr", 2, data.message);
              //utils.gotoPage("/admin/members");
              setTimeout(() => {
                this.props.onCancelMember();
              }, 1000);
            })
            .catch((error) => {
              utils.hideLoader();
              let err = error.response;
              console.log("errors", err);
              this.child.current.notify("tr", 3, this.handleError(err));
              const err401 = error.response;
              utils.logoutUnauthorized(err401);
            });
        } else if (this.props.isAddOrEditMember === "edit") {
          let editedData = {
            id: this.props.onEditMember._id,
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile,
            type: this.state.type,
            address: this.state.address,
            code: this.state.code,
          };
          console.log("valid fields edit", editedData);
          utils
            .makeRequest(this, EndpointConstant.UPDATE_USER, "post", editedData)
            .then((response) => {
              utils.hideLoader();
              let data = response.data;
              console.log("response edit", response);
              this.child.current.notify("tr", 2, data.message);
              //utils.gotoPage("/admin/members");
              setTimeout(() => {
                this.props.onCancelMember();
              }, 1000);
            })
            .catch((error) => {
              utils.hideLoader();
              let err = error.response;
              console.log("errors", err);
              if (err.data.success !== true) {
                this.child.current.notify("tr", 3, err.data.message);
              }
              const err401 = error.response;
              utils.logoutUnauthorized(err401);
            });
        } else {
          this.props.onCancelMember();
        }
      } else {
        console.log("Invalid fields");
      }
    }, 500);
  }
  handleError(data) {
    let msg = "";
    if (!data.data.error.success) {
      let responseMsg = data.data.error.message;
      if (
        responseMsg.hasOwnProperty("email") &&
        responseMsg.hasOwnProperty("mobile")
      ) {
        msg = MessageConstant.EXISTS_EMAIL_MOBILE;
      } else if (responseMsg.hasOwnProperty("email")) {
        msg = MessageConstant.EXISTS_EMAIL;
      } else if (responseMsg.hasOwnProperty("mobile")) {
        msg = MessageConstant.EXISTS_MOBILE;
      }
    }
    return msg;
  }
  render() {
    const {
      code,
      name,
      password,
      email,
      mobile,
      type,
      address,
      errors,
    } = this.state;
    return (
      <>
        <div className="content">
          <ToasterMessage ref={this.child} />
          <Row>
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">
                    {this.props.isAddOrEditMember !== "new"
                      ? "Edit Member"
                      : "New Member"}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.addMember}>
                    <Row>
                      <Col className="pr-1" md="2">
                        <FormGroup>
                          <label>Code</label>
                          <Input
                            /* disabled={
                              this.props.isAddOrEditMember == "new"
                                ? false
                                : true
                            } */
                            placeholder="Company"
                            type="text"
                            name="code"
                            value={code}
                            onChange={this.handleOnChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="5">
                        <FormGroup>
                          <label className="required">Name</label>
                          <Input
                            placeholder="Name"
                            type="text"
                            name="name"
                            value={name}
                            onChange={this.handleOnChange}
                          />
                          <p className="text-danger">{errors.name}</p>
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="5">
                        <FormGroup>
                          <label className="required">Password</label>
                          <Input
                            placeholder="Password"
                            type="password"
                            name="password"
                            value={password}
                            onChange={this.handleOnChange}
                            disabled={this.props.isAddOrEditMember !== "new"}
                          />
                          <p className="text-danger">{errors.password}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label className="required">Email</label>
                          <Input
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={email}
                            onChange={this.handleOnChange}
                          />
                          <p className="text-danger">{errors.email}</p>
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label className="required">Mobile</label>
                          <Input
                            placeholder="Mobile"
                            type="text"
                            name="mobile"
                            value={mobile}
                            onChange={this.handleOnChange}
                          />
                          <p className="text-danger">{errors.mobile}</p>
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label className="required">Type</label>
                          <Input
                            type="select"
                            name="type"
                            value={type}
                            onChange={this.handleOnChange}>
                            <option value="">Select Type</option>
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                          </Input>
                          <p className="text-danger">{errors.type}</p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Address</label>
                          <Input
                            type="textarea"
                            name="address"
                            value={address}
                            onChange={this.handleOnChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit">
                          Save
                        </Button>
                        <Button
                          className="btn-round"
                          color="secondary"
                          onClick={this.handleCancel}>
                          Cancel
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AddMember;
