/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./Expenditure.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import { Calendar } from "primereact/calendar";
import utils from "../../utils";
import EndpointConstant from "../../constants/EndpointConstant";
import MessageConstant from "../../constants/MessageConstant";
import ToasterMessage from "../Notification/ToasterMessage";
import moment from "moment";

class FilterExpenditure extends React.Component {
  constructor(props) {
    super(props);
    console.log("add new props", props);

    this.state = {
      name: "",
      categoryType: "",
      startDate: "",
      endDate: "",
    };
    this.child = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this);
    this.filterExpenditure = this.filterExpenditure.bind(this);
    this.handleOnClear = this.handleOnClear.bind(this);
  }

  componentDidMount() {}

  handleOnChange(event) {
    const { name, value } = event.target;
    if (name === "startDate" || name === "endDate") {
      console.log("selected date", moment(value).unix());
    }
    this.setState({
      [name]: value,
    });
  }

  handleOnClear(event) {
    event.preventDefault();
    this.setState({
      name: "",
      categoryType: "",
      startDate: "",
      endDate: "",
    });
    this.props.onClearReset(true);
  }
  filterExpenditure(event) {
    event.preventDefault();
    const { name, startDate, endDate, categoryType } = this.state;
    console.log("this.state", this.state);
    if (name == "" && startDate == "" && endDate == "" && categoryType == "") {
      this.child.current.notify(
        "tr",
        3,
        MessageConstant.FILTER_CATEGORY_REQUIRED
      );
    } else if (
      (startDate != "" && endDate == "") ||
      (startDate == "" && endDate != "")
    ) {
      this.child.current.notify("tr", 3, MessageConstant.DATE_RANGE_REQUIRED);
    } else {
      this.props.onClearReset(false);
      let startDate = moment(this.state.startDate).unix();
      let endDate = moment(this.state.endDate).unix();
      let data = {
        name: this.state.name,
        startDate: startDate,
        endDate: endDate,
        type: categoryType,
      };
      console.log("this date", data);
      utils
        .makeRequest(this, EndpointConstant.FILTER_EXPENDITURE, "post", data)
        .then((response) => {
          console.log("filter response", response);
          this.props.onFilteredData(response.data);
          utils.hideLoader();
        })
        .catch((error) => {
          console.log("error", error);
          utils.hideLoader();
          const err401 = error.response;
          utils.logoutUnauthorized(err401);
        });
    }
  }
  render() {
    return (
      <Card className="cardRoot">
        <CardBody className="filteredBody">
          <CardTitle className="cardTitle">Search Expenditure</CardTitle>
          <ToasterMessage ref={this.child} />
          <div className="filteredContainer">
            <Form onSubmit={this.filterExpenditure}>
              <Row className="filterRow">
                <h5>Filter by: </h5>
                <Col className="pr-1" md="2">
                  <FormGroup>
                    <Input
                      type="select"
                      name="categoryType"
                      id="categoryType"
                      value={this.state.categoryType}
                      onChange={this.handleOnChange}>
                      <option value="">Select Type</option>
                      <option value="contribution">Contribution</option>
                      <option value="zakath">Zakath</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col className="pr-1" md="2">
                  <FormGroup>
                    <Input
                      placeholder="Category"
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleOnChange}
                    />
                  </FormGroup>
                </Col>
                <Col className="pr-1" md="2">
                  <FormGroup>
                    <Calendar
                      inputStyle={{ width: "80%", border: "1px solid #ddd" }}
                      showIcon={true}
                      value={this.state.startDate}
                      dateFormat="dd/mm/yy"
                      placeholder="Start Date"
                      name="startDate"
                      onChange={this.handleOnChange}
                      monthNavigator
                      yearNavigator
                      yearRange="2014:2030"></Calendar>
                  </FormGroup>
                </Col>

                <Col className="pr-1" md="2">
                  <FormGroup>
                    <Calendar
                      inputStyle={{ width: "80%", border: "1px solid #ddd" }}
                      showIcon={true}
                      value={this.state.endDate}
                      dateFormat="dd/mm/yy"
                      placeholder="End Date"
                      name="endDate"
                      onChange={this.handleOnChange}
                      monthNavigator
                      yearNavigator
                      yearRange="2014:2030"></Calendar>
                  </FormGroup>
                </Col>
                <Col className="pr-1" md="2">
                  <FormGroup className=" d-inline-flex filtered-button">
                    <Button
                      className="btn-round ml-4"
                      color="primary"
                      type="submit">
                      search
                    </Button>
                    <Button
                      className="btn-round ml-4"
                      color="secondary"
                      onClick={this.handleOnClear}>
                      Clear
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default FilterExpenditure;
