import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ModelPopup(props) {
  return (
    <React.Fragment>
      <Modal isOpen={props.isOpen} toggle={props.handleOnClose}>
        <ModalHeader toggle={props.handleOnClose}>Confirmation</ModalHeader>
        <ModalBody>{props.Message}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.handleOnConfirmation}>
            Yes
          </Button>
          <Button color="secondary" onClick={props.handleOnClose}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default ModelPopup;
