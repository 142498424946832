const MessageConstant = {};
MessageConstant.MOBILE_REQUIRED = "Mobile number required";
MessageConstant.PASSWORD_REQUIRED = "Password required";
MessageConstant.ALL_FIELD_REQUIRED = "All fields are required";
MessageConstant.NAME_REQUIRED = "Name required";
MessageConstant.CODE_REQUIRED = "Code required";
MessageConstant.EMAIL_REQUIRED = "Email required";
MessageConstant.TYPE_REQUIRED = "Member type required";
MessageConstant.EXISTS_EMAIL = "Email id already exists in our database";
MessageConstant.EXISTS_MOBILE = "Mobile number already exists in our database";
MessageConstant.EXISTS_EMAIL_MOBILE =
  "Email id and mobile number already exists in our database";
MessageConstant.CONFIRM_ACTIVE_DEACTIVE =
  "Do you want to Activate/Deactivate the Member?";
MessageConstant.CONFIRM_DELETE_USER = "Do you really want to delete a member?";
MessageConstant.MEMBER_REQUIRED = "Member required";
MessageConstant.GUEST_REQUIRED = "Guest required";
MessageConstant.DATE_REQUIRED = "Date required";
MessageConstant.AMOUNT_REQUIRED = "Amount required";
MessageConstant.PAYMETHOD_REQUIRED = "Payment type required";
MessageConstant.REMARKS_REQUIRED = "Remarks required";
MessageConstant.CONFIRM_DELETE_CONTRIBUTION =
  "Do you really want to delete contribution?";
MessageConstant.CONFIRM_DELETE_ZAKATH = "Do you really want to delete zakath?";
MessageConstant.CATEGORY_REQUIRED = "Category required";
MessageConstant.CONFIRM_DELETE_EXPENDITURE =
  "Do you really want to delete expenditure?";
MessageConstant.ALERT_AMOUNT_CONTRIBUTION = (availableContribution) => {
  return `Please enter amount lesser than the available contribution amount(Rs.${availableContribution}/-)`;
};
MessageConstant.ALERT_AMOUNT_ZAKATH = (availableZakath) => {
  return `Please enter amount lesser than the available zakath amount(Rs.${availableZakath}/-)`;
};
MessageConstant.FILTER_FIED_REQUIRED =
  "Please enter name or Date range to filter";
MessageConstant.DATE_RANGE_REQUIRED = "Please select the date range to filter";
MessageConstant.FILTER_CATEGORY_REQUIRED =
  "Please enter category or date range to filter";
MessageConstant.NEW_PASSWORD_REQUIRED = "New password required";
MessageConstant.CONFIRM_PASSWORD_REQUIRED = "Confirm password required";
MessageConstant.UNMATCHED_PASSWORD =
  "Password must match with confirm password";
MessageConstant.INVALID_EMAIL_ID = "Please provide a valid email id";
MessageConstant.SAME_EMAIL_ID =
  "You have given the same email id, Please provide different email id to update";
export default MessageConstant;
